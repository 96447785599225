@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-8px);
  }
  100% {
    transform: translatey(0px);
  }
}
.bounce {
  transform: translatey(0px);
  animation: float 1s ease infinite;
  overflow: hidden;
}
